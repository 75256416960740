<template>
  <div
    class="white text-body-2 black--text mt-5 mb-5 pt-6 pb-6 pl-8 pr-8 d-flex align-center rounded-lg"
  >
    <div
      class="info--date font-weight-bold info--number text-truncate text-uppercase"
    >
      {{ getDate(item.dateCreated) }}
    </div>
    <div class="info--name text-truncate text-uppercase">
      {{ item.client }}
    </div>
    <div class="info--validity text-uppercase text-truncate text-center">
      {{ item.days }} dias
    </div>
    <div
      class="info--price text-uppercase text-truncate font-weight-black text-center"
    >
      {{ item.price | formatCurrency}}
    </div>
    <div
      class="info--status text-uppercase text-truncate text-center font-weight-black"
      :class="item.status === 'PAGADA' ? 'green--text' : 'orange--text'"
    >
      {{ item.status }}
    </div>
    <div class="info--actions d-flex justify-center">
      <v-btn
        v-if="item.status === 'PAGADA'"
        depressed
        outlined
        color="primary"
        @click="print"
        >IMPRIMIR</v-btn
      >

      <v-btn class="px-7" v-else depressed color="primary" @click="toPay"
        >PAGAR</v-btn
      >
    </div>

    <component
      :is="component"
      :dialog="dialog"
      :dialogConfirm="dialogConfirm"
      :body="body"
      @cancel="dialog = false"
      @confirm="dialogConfirm = false"
      @toPay="toPayed"
    >
    </component>
  </div>
</template>

<script>
// EventBus
import Bus from "../../../common/EventBus";

// Dominas
import Purchase from "../../../domain/Purchase";

// Objects
import PrintTicket from "../../../helpers/PrintTicket";

export default {
  name: "InternetMovilTableItem",
  components: {
    ModalConfirm: () => import("../../base/ModalConfirm"),
    ConfirmPayment: () => import("../ConfirmPayment"),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    component: "ConfirmPayment",
    dialogConfirm: false,
    dialog: false,
    body: {
      title: "PAGADO CORRECTAMENTE",
      message: "Recarga pagada correctamente",
    },
  }),
  methods: {
    toPay() {
      this.dialogConfirm = false;
      this.dialog = true;
    },
    async toPayed() {
      try {
        const response = await Purchase.purchaseInternetPlan(this.item._id);
        console.log(response);
        Bus.$emit("refreshView");
        this.component = "ModalConfirm";
        this.dialog = false;
        this.dialogConfirm = true;
      } catch (error) {
        console.warn(error);
      }
    },
    getDate(date) {
      return new Intl.DateTimeFormat("es-MX", {
        dateStyle: "medium",
        timeStyle: "short",
        hour12: true,
      }).format(new Date(date));
    },
    print() {
      PrintTicket.InternetMovil(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>